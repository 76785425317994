<template>
    <div>
        <h1>404 Error</h1>
        <p>The resource could not be found.</p>
        <router-link to="/" exact>
            Go to home page
        </router-link>
    </div>
</template>

<script type="text/javascript">
export default {
  name: 'NotNot'
}
</script>
